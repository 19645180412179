import React from 'react'

import styles from "./../../../policies-css-module.module.scss"
import '../../../../styles.scss'

import { Layout } from '../../../../components'

import RamadanPolicy from '../../../../components/policies/ramadanPolicy/ramadanPolicy'

import { Link } from "gatsby"

const RamadanFestiveOffer = () => {
	return (
		<>
			<Layout>
					<div className={styles.container}>
						<div className={styles.link_section}>
							<div className={styles.link_section_div}>
								<Link exact className={styles.tc_links} activeClassName='is-active' to='/promos/ramadanfestiveoffer/tnc/'>RAMADAN FESTIVAL OFFER</Link>
							</div>
						</div>
						<div className={styles.content_section}>
								<RamadanPolicy />
						</div>
					</div>
			</Layout>
		</>
	)
}

export default RamadanFestiveOffer
